import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const AboutMe = loadable(() => import('../components/About'))
const Contact = loadable(() => import('../components/Contact'))
const HeroArea = loadable(() => import('../components/HeroArea'))
const Services = loadable(() => import('../components/Services'))
const Preloader = loadable(() => import('../components/Preloader'))
const ShowcaseProject = loadable(() => import('../components/ShowcaseProject'))
const Features = loadable(() => import('../components/Features'))
const CTA = loadable(() => import('../components/CTA'))
import { getAllPublishedListings } from '../lib/notion'

import { useSlideOut } from '../providers/SlideOutContext'

const Home = ({ listings }) => {
  const { toggleSlideOut } = useSlideOut()
  return (
    <div className="rokstar">
      <Header />
      <Preloader />
      <HeroArea />
      <AboutMe />
      <Services />
      <Features />
      <ShowcaseProject listing={listings[0]} />
      {/* <MasonryGallery /> */}
      <CTA
        title="Get a Free Estimate"
        ctaText="Schedule an Appointment"
        onClick={toggleSlideOut}
      />
      <Contact />
    </div>
  )
}

export default Home

export const getStaticProps = async () => {
  const listings = await getAllPublishedListings()
  return {
    props: {
      listings,
    },
    revalidate: 60,
  }
}
